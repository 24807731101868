var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "text-h4 mt-5" }, [
              _vm._v("\n        Overview\n      ")
            ])
          ]),
          _c(
            "v-col",
            {
              staticClass: "mt-4",
              attrs: { cols: "6", sm: "6", md: "4", lg: "3" }
            },
            [
              _vm.isMMSAdmin
                ? _c("v-select", {
                    attrs: {
                      "v-model": _vm.selectedUser,
                      dense: "",
                      "hide-details": "",
                      items: _vm.getCsmUsers,
                      "item-value": "value",
                      outlined: "",
                      label: "View as CSM",
                      color: "primary",
                      loading: _vm.loading
                    },
                    on: { change: _vm.getCSMData }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-10" },
        [
          _c(
            "v-tabs",
            {
              attrs: { "center-active": "", "fixed-tabs": "" },
              model: {
                value: _vm.csmTab,
                callback: function($$v) {
                  _vm.csmTab = $$v
                },
                expression: "csmTab"
              }
            },
            [
              _c(
                "v-tab",
                { style: [{ "font-size": "20px", height: "60px" }] },
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        color: "primary",
                        content: (_vm.activeProjects.length = 0
                          ? 0
                          : _vm.activeProjects.length)
                      }
                    },
                    [_vm._v("\n          Active Projects\n        ")]
                  )
                ],
                1
              ),
              _c(
                "v-tab",
                { style: [{ "font-size": "20px", height: "60px" }] },
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        color: "#ff7235",
                        content: (_vm.npcData.length = 0
                          ? 0
                          : _vm.npcData.length)
                      }
                    },
                    [_vm._v("\n          Inactive NPC Customers\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mt-8" },
        [
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.csmTab,
                callback: function($$v) {
                  _vm.csmTab = $$v
                },
                expression: "csmTab"
              }
            },
            [
              _c(
                "v-row",
                { attrs: { justify: "end", align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-6", attrs: { cols: "auto", sm: "3" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "append-icon": "mdi-magnify",
                          label: "Search",
                          "single-line": "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.csmTab === 0
                ? _c("v-data-table", {
                    attrs: {
                      "item-key": "name",
                      headers: _vm.activeProjectsHeaders,
                      items: _vm.activeProjects,
                      search: _vm.search,
                      loading: _vm.activeProjectsLoading
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.customerName",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.redirectToDashboard(
                                        item.tenantId,
                                        item.nowwId,
                                        "active"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a",
                                    { staticStyle: { cursor: "pointer" } },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.customerName) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          }
                        },
                        {
                          key: "item.campaignName",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("div", [
                                _c("span", [_vm._v(_vm._s(item.campaignName))])
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.modified",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("displayDateFormat")(item.modified)
                                    )
                                  )
                                ])
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.mailings",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._l(item.mailings, function(mailing) {
                                return _c(
                                  "v-tooltip",
                                  {
                                    key: mailing.id,
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      color: _vm.getMailingIconColor(
                                                        mailing
                                                      ),
                                                      left: ""
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.getMailingIcon(
                                                          mailing
                                                        )
                                                      ) +
                                                      "\n              "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(mailing.statusDisplay))
                                    ])
                                  ]
                                )
                              }),
                              item.numberOfBroadcasts >= item.mailings.length
                                ? _vm._l(
                                    item.numberOfBroadcasts -
                                      item.mailings.length,
                                    function(i) {
                                      return _c(
                                        "v-tooltip",
                                        {
                                          key: "empty-" + i,
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            color: "grey",
                                                            left: ""
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "\n                  mdi-email-outline\n                "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [_c("span", [_vm._v("Not Created")])]
                                      )
                                    }
                                  )
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "item.campaignExpiration",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(item.campaignExpiration + " days")
                                  )
                                ])
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                : _c("v-data-table", {
                    attrs: {
                      "item-key": "name",
                      headers: _vm.inactiveNpcHeaders,
                      items: _vm.npcData,
                      search: _vm.search,
                      loading: _vm.npcDataLoading
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.customerName",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.redirectToDashboard(
                                        item.tenantId,
                                        item.nowwId,
                                        "npc"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a",
                                    { staticStyle: { cursor: "pointer" } },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.customerName) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          }
                        },
                        {
                          key: "item.campaignName",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("div", [
                                _c("span", [_vm._v(_vm._s(item.campaignName))])
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.lastSend",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("displayDateFormat")(item.lastSend)
                                    )
                                  )
                                ])
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.package",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.getPackageName(item.package))
                                  )
                                ])
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.contractExpiration",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getExpirationDays(
                                        item.contractExpiration
                                      ) + " days"
                                    )
                                  )
                                ])
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }