<template>
  <div>
    <v-row>
      <v-col>
        <div class="text-h4 mt-5">
          Overview
        </div>
      </v-col>
      <v-col
        class="mt-4"
        cols="6"
        sm="6"
        md="4"
        lg="3"
      >
        <v-select
          v-if="isMMSAdmin"
          :v-model="selectedUser"
          dense
          hide-details
          :items="getCsmUsers"
          item-value="value"
          outlined
          label="View as CSM"
          color="primary"
          :loading="loading"
          @change="getCSMData"
        />
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-tabs
        v-model="csmTab"
        center-active
        fixed-tabs
      >
        <v-tab
          :style="[{ 'font-size': '20px', 'height': '60px'}]"
        >
          <v-badge
            color="primary"
            :content="activeProjects.length = 0 ? 0 : activeProjects.length"
          >
            Active Projects
          </v-badge>
        </v-tab>
        <v-tab
          :style="[{ 'font-size': '20px', 'height': '60px'}]"
        >
          <v-badge
            color="#ff7235"
            :content="npcData.length = 0 ? 0 : npcData.length"
          >
            Inactive NPC Customers
          </v-badge>
        </v-tab>
      </v-tabs>
    </v-row>
    <v-card class="mt-8">
      <v-tabs-items v-model="csmTab">
        <v-row
          justify="end"
          align="center"
        >
          <v-col
            cols="auto"
            class="pr-6"
            sm="3"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-col>
        </v-row>
        <v-data-table
          v-if="csmTab === 0"
          item-key="name"
          :headers="activeProjectsHeaders"
          :items="activeProjects"
          :search="search"
          :loading="activeProjectsLoading"
        >
          <template v-slot:[`item.customerName`]="{ item }">
            <div @click="redirectToDashboard(item.tenantId, item.nowwId, 'active')">
              <a style="cursor: pointer;">
                {{ item.customerName }}
              </a>
            </div>
          </template>
          <template v-slot:[`item.campaignName`]="{ item }">
            <div>
              <span>{{ item.campaignName }}</span>
            </div>
          </template>
          <template v-slot:[`item.modified`]="{ item }">
            <div>
              <span>{{ item.modified | displayDateFormat }}</span>
            </div>
          </template>
          <template v-slot:[`item.mailings`]="{ item }">
            <v-tooltip
              v-for="(mailing) in item.mailings"
              :key="mailing.id"
              top
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  :color="getMailingIconColor(mailing)"
                  left
                  v-on="on"
                >
                  {{ getMailingIcon(mailing) }}
                </v-icon>
              </template>
              <span>{{ mailing.statusDisplay }}</span>
            </v-tooltip>
            <template v-if="item.numberOfBroadcasts >= item.mailings.length">
              <v-tooltip
                v-for="i in item.numberOfBroadcasts - item.mailings.length"
                :key="`empty-${i}`"
                top
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    color="grey"
                    left
                    v-on="on"
                  >
                    mdi-email-outline
                  </v-icon>
                </template>
                <span>Not Created</span>
              </v-tooltip>
            </template>
          </template>
          <template v-slot:[`item.campaignExpiration`]="{ item }">
            <div>
              <span>{{ item.campaignExpiration + ' days' }}</span>
            </div>
          </template>
        </v-data-table>
        <v-data-table
          v-else
          item-key="name"
          :headers="inactiveNpcHeaders"
          :items="npcData"
          :search="search"
          :loading="npcDataLoading"
        >
          <template v-slot:[`item.customerName`]="{ item }">
            <div @click="redirectToDashboard(item.tenantId, item.nowwId, 'npc')">
              <a style="cursor: pointer;">
                {{ item.customerName }}
              </a>
            </div>
          </template>
          <template v-slot:[`item.campaignName`]="{ item }">
            <div>
              <span>{{ item.campaignName }}</span>
            </div>
          </template>
          <template v-slot:[`item.lastSend`]="{ item }">
            <div>
              <span>{{ item.lastSend | displayDateFormat }}</span>
            </div>
          </template>
          <template v-slot:[`item.package`]="{ item }">
            <div>
              <span>{{ getPackageName(item.package) }}</span>
            </div>
          </template>
          <template v-slot:[`item.contractExpiration`]="{ item }">
            <div>
              <span>{{ getExpirationDays(item.contractExpiration) + ' days' }}</span>
            </div>
          </template>
        </v-data-table>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { reportService, tenantService } from '@/shared/services'
import { mapGetters, mapActions } from 'vuex'
import { mailingItemStatus } from '@/shared/models/mailing'
import mutationTypes from '@/store/mutation-types'
import moment from 'moment'
import claims from '@/shared/models/auth/claims'

export default {
  name: 'CsmDashboard',

  data () {
    return {
      search: '',
      selectedUser: '',
      csmTab: null,
      csmUsers: [],
      npcData: [],
      activeProjects: [],
      loading: false,
      activeProjectsLoading: false,
      npcDataLoading: false,
      inactiveNpcHeaders: [
        {
          text: 'Customer',
          align: 'start',
          class: 'title',
          value: 'customerName'
        },
        { text: 'Campaign', value: 'campaignName', class: 'title' },
        { text: 'Last Send', align: 'center', value: 'lastSend', class: 'title' },
        { text: 'Subscription Package', align: 'center', value: 'package', class: 'title' },
        { text: 'Contract Exp', align: 'center', value: 'contractExpiration', class: 'title' }
      ],
      activeProjectsHeaders: [
        {
          text: 'Customer',
          align: 'start',
          class: 'title',
          filterable: true,
          value: 'customerName'
        },
        { text: 'Campaign', value: 'campaignName', class: 'title' },
        { text: 'Modified', value: 'modified', class: 'title' },
        { text: 'Mailing', sortable: false, filterable: false, value: 'mailings', class: 'title' },
        { text: 'Campaign Exp', align: 'center', value: 'campaignExpiration', class: 'title' }
      ],
      data: []
    }
  },

  computed: {
    ...mapGetters('auth', ['getUserId', 'hasClaimKV', 'getCrmUserId', 'getTenantCrmId', 'viewAsCustomer']),
    isMMSAdmin () {
      return this.hasClaimKV(claims.MMS_TM)
    },
    getCsmUsers () {
      var csm = [{ text: 'Current User', value: 0 }]
      for (var i = 0; i < this.csmUsers.length; i++) {
        if (this.csmUsers[i].email.toLowerCase().includes('@mmslists.com') && this.csmUsers[i].crmUserId != null) {
          csm.push({
            text: this.csmUsers[i].firstName + ' ' + this.csmUsers[i].lastName,
            value: this.csmUsers[i].crmUserId
          })
        }
      }
      return csm
    }
  },

  created () {
    this.loadUsers()
    this.getActiveProjects(this.getCrmUserId())
    this.getNPCList(this.getCrmUserId())
  },

  methods: {
    ...mapActions('auth', ['setViewAsCustomer']),
    async getActiveProjects (crmUserId) {
      this.activeProjectsLoading = true
      await reportService.getActiveProjects(crmUserId)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.activeProjects = resp
            this.activeProjectsLoading = false
          }
        })
    },
    async getNPCList (crmUserId) {
      this.npcDataLoading = true
      await reportService.getNPCList(crmUserId)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.npcData = resp
            this.npcDataLoading = false
          }
        })
    },
    getCSMData (selectedUser) {
      this.npcData = []
      this.activeProjects = []
      this.getActiveProjects(selectedUser)
      this.getNPCList(selectedUser)
    },
    loadUsers () {
      this.loading = true
      tenantService.getUsersByTenantCrm(this.getTenantCrmId()).then(resp => {
        if (resp.errorResponse) {
          this.$store.commit('setGlobalSnackbar', {
            message: resp.errorResponse,
            color: 'error'
          })
          this.csmUsers = []
        } else {
          this.csmUsers = resp
          this.loading = false
        }
      })
    },
    redirectToDashboard (crmId, nowwId, type) {
      var isContracted = false
      if (type === 'npc') {
        isContracted = true
      }
      this.$store.dispatch('setViewAsCustomer', { crmId, nowwId, isContracted })
      this.$router.push('/dashboard/campaign')
    },
    getExpirationDays (expirationDate) {
      if (expirationDate == null) {
        return 0
      }
      var formatExpirationdate = this.$options.filters.displayDateFormat(expirationDate)
      var exp = moment(expirationDate)
      var now = new Date()
      var days = exp.diff(now, 'days')

      return formatExpirationdate + ' | ' + days
    },
    getPackageName (packageId) {
      switch (packageId) {
        case 0:
          return 'Direct Mail Premium'
        case 1:
          return 'Digital Pro'
        case 2:
          return 'Digital Pro Plus'
        case 3:
          return 'Digital Enterprise'
        case 4:
          return 'MMS'
      }
    },
    getMailingIconColor (mailing) {
      return mailingItemStatus.find(x => x.value === mailing.status).color
    },
    getMailingIcon (mailing) {
      switch (mailing.status) {
        case 1:// draft
          if (mailing.discriminator === 'MailingItem') {
            return 'mdi-email'
          } else {
            return 'mdi-email-sync'
          }
        case 2:// tested
          return 'mdi-test-tube'
        case 3:// scheduled
          return 'mdi-send-clock'
        case 4:// sent
          return 'mdi-send-check'
        case 5:// canceled
          return 'mdi-email-off-outline'
        case 100:// sending
          return 'mdi-email-fast'
      }
    }
  }
}
</script>
